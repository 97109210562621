import React from 'react'
import Helmet from 'react-helmet'

const Seo = ({ location = { pathname: `/` }, seo = {} }) => {
  const title = 'adidas JDZX SS22';
  const siteUrl = location.origin;

  const defaultTags = {
    title: seo.meta_title,
    description: seo.meta_description,
    canonical: siteUrl + location.pathname,
    author: seo.meta_author,
    image: seo.meta_image
  }

  const ogTags = {
    url: siteUrl + location.pathname,
    type: seo.og_type,
    title: seo.og_title,
    description: seo.og_description,
    image: seo.og_image
  }

  const twitterTags = {
    card: seo.twitter_card,
    title: seo.twitter_title,
    description: seo.twitter_description,
    site: seo.twitter_site,
    creator: seo.twitter_creator,
    image: seo.twitter_image
  }

  return (
    <Helmet defaultTitle={title}>
      <html lang={`en-GB`} />

      <title>{(defaultTags.title ? defaultTags.title + ` | ` : '') + title}</title>
      {defaultTags.canonical && <link rel="canonical" href={defaultTags.canonical} />}

      {defaultTags.description && <meta name="description" content={defaultTags.description} />}
      {defaultTags.author && <meta name="author" content={defaultTags.author} />}
      {defaultTags.image && <meta name="image" content={defaultTags.image} />}
      <meta
        name="viewport"
        content="width=device-width,minimum-scale=1.0,initial-scale=1.0,maximum-scale=5.0,viewport-fit=cover"
      />

      <script type="application/ld+json">
        {`{
              "@context":"https://schema.org",
              "@type":"Organization",
              "url":"https://jd-zx-ss22.adidasdigital.com/",
            }`}
      </script>

      {Object.keys(ogTags).map((key) => (
        ogTags[key] ? <meta key={`og:${key}`} property={`og:${key}`} content={ogTags[key]} /> : null
      ))}

      {Object.keys(twitterTags).map((key) => (
        twitterTags[key] ? <meta key={`twitter:${key}`} name={`twitter:${key}`} content={twitterTags[key]}/> : null
      ))}
    </Helmet>
  )
}

export default Seo;