import { Box, Center, Image } from "@chakra-ui/react";
import { motion } from "framer-motion";
import * as React from "react";
import logoSrc from "../assets/logo.svg";
import decoration1Src from "../assets/decoration-1.svg";
import decoration2Src from "../assets/decoration-2.svg";
import Seo from "./molecules/Seo";

const Layout = ({ location, simple = false, seo, children }) => (
  <Box>
    <Seo location={location} seo={seo}/>
    <Center position="absolute" top="0" left="0" w="100%" pt="12" pb="6">
      <Image src={logoSrc} alt="adidas JDZX SS22" width="auto"/>
    </Center>
    <Center as="main" minHeight="100vh" pt="9.6rem" pb="16">
      <Box width="100%">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            type: "spring",
            mass: 0.35,
            stiffness: 75,
            duration: 0.3
          }}
        >
          {children}
        </motion.div>
      </Box>
    </Center>
    <Image position="absolute" top="3" left="4" opacity=".05" maxHeight="95%" src={decoration1Src} alt="The Next Destination"/>
    <Image position="absolute" top="10" right="4" src={decoration2Src} alt="JDZX 2022"/>
  </Box>
);

export default Layout;